.page-header.navbar .page-logo .logo-default {
    margin: 19px 10px 0 10px;
}

.page-header.navbar.navbar-fixed-top {
    z-index: 1000;
}

.page-header.navbar.navbar-static-top {
    z-index: 1000;
}

.page-footer-inner {
    padding-bottom: 10px;
}

.md-shadow-zero-light {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.18) !important;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-submenu {

    &:hover > a {
        background-color: #5b6f7c !important;
    }

    > a.open-left:after {
        content: '\f104' !important;
        left: 7px !important;
    }

    > ul.dropdown-menu {
        margin-top: -5px !important;

        &:after {
            display: none !important;
        }

        li.divider {
            background-color: #607482 !important;
        }
    }
}

/* Begin Notification Bar */
div.user-notification-item {
    .m-list-timeline__text {
        color: #dadada !important;
    }

    &.user-notification-item-clickable {
        cursor: pointer;
    }

    &.user-notification-item-unread {
        .m-list-timeline__text {
            color: #575962 !important;
        }
    }
}
/* End Notification Bar */
.jcrop-keymgr {
    opacity: 0;
}

#ProfilePictureResize {
    width: 200px;
    height: 200px;
}

#loginAttemptsModalForm .control-label {
    font-weight: bold;
}

.subscription-info {
    clear: both !important;
    padding: 5px !important;
    background-color: #fffced !important;
    font-size: 0.9em !important;
    color: #755d2d !important;
}

@media only screen and (max-device-width: 640px) {
    .edition-info-text {
        display: none;
    }
}

li.dropdown-language {
    a {
        padding-top: 25px !important;
    }

    ul {
        top: 0px !important;
        min-width: 200px;

        li a {
            padding-top: 5px !important;
        }
    }
}

@media (max-width: 992px) {
    li.dropdown-language {
        a {
            padding-top: 15px !important;
        }
    }
}

@media (min-width: 993px) {
    #m_header_nav {
        height: auto !important;
    }
}

ul.linked-accounts li {
    margin-left: 35px !important;
}

span.m-topbar__username {
    padding-right: 10px;
}

@media (max-width: 450px) {
    .tenancy-name {
        display: none !important;
    }

    .m-menu__link-text.langname {
        display: none !important;
    }

    .dropdown-language {
        i.famfamfam-flags {
            margin-right: 3px !important;
        }
    }
}

@media (min-width: 1400px) {
    body.m-page--boxed {
        .subscription-info {
            width: 1340px;
            margin-right: auto;
            margin-left: auto;
        }
    }
}
