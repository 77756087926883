
/* -------------------------------- 

Main Components 

-------------------------------- */
.cd-accordion-menu {
	/*max-width: 405px;*/
}
.cd-accordion-menu ul {
  /* by default hide all sub menus */
  display: none;
}
.cd-accordion-menu li {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cd-accordion-menu input[type=checkbox] {
  /* hide native checkbox */
  position: absolute;
  opacity: 0;
}
.cd-accordion-menu label, .cd-accordion-menu a {
  position: relative;
  display: block;
  padding: 18px 18px 18px 64px;
  /*background: #4d5158;	
  box-shadow: inset 0 -1px #555960;
  color: #ffffff;*/
	background: #EFF3F6;
    box-shadow: inset 0 -1px #e6eaee;
    color: #354052;
  font-size: 1.6rem;
  font-family: Source Sans Pro,sans-serif;
}
.no-touch .cd-accordion-menu label:hover, .no-touch .cd-accordion-menu a:hover {
  background: #52565d;
}
.cd-accordion-menu label::before, .cd-accordion-menu label::after, .cd-accordion-menu a::after {
  /* icons */
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 44%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.cd-accordion-menu label {
  cursor: pointer;
  color: #7f8fa4;
}

.cd-accordion-menu label::before {
  /* arrow icon */
  left: 18px;
  background-position: 0 0;
  -webkit-transform: translateY(-50%) rotate(-90deg);
  -moz-transform: translateY(-50%) rotate(-90deg);
  -ms-transform: translateY(-50%) rotate(-90deg);
  -o-transform: translateY(-50%) rotate(-90deg);
  transform: translateY(-50%) rotate(-90deg);
}
.cd-accordion-menu label::after {
  /* folder icons */
  left: 41px;
  background-position: -16px 0;
}
.cd-accordion-menu input[type=checkbox]:checked + label::before {
  /* rotate arrow */
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.cd-accordion-menu input[type=checkbox]:checked + label::after {
  /* show open folder icon if item is checked */
  background-position: -32px 0;
}
.cd-accordion-menu input[type=checkbox]:checked + label + ul,
.cd-accordion-menu input[type=checkbox]:checked + label:nth-of-type(n) + ul {
  /* use label:nth-of-type(n) to fix a bug on safari (<= 8.0.8) with multiple adjacent-sibling selectors*/
  /* show children when item is checked */
  display: block;
}
.cd-accordion-menu ul label,
.cd-accordion-menu ul a {
  background: #35383d;
  box-shadow: inset 0 -1px #e6eaee;
  padding-left: 82px;
}
.no-touch .cd-accordion-menu ul label:hover, .no-touch
.cd-accordion-menu ul a:hover {
  background: #3c3f45;
}
.cd-accordion-menu > li:last-of-type > label,
.cd-accordion-menu > li:last-of-type > a,
.cd-accordion-menu > li > ul > li:last-of-type label,
.cd-accordion-menu > li > ul > li:last-of-type a {
  box-shadow: none;
}
.cd-accordion-menu ul label::before {
  left: 36px;
}
.cd-accordion-menu ul label::after,
.cd-accordion-menu ul a::after {
  left: 59px;
}
.cd-accordion-menu ul ul label,
.cd-accordion-menu ul ul a {
  padding-left: 100px;
}
.cd-accordion-menu ul ul label::before {
  left: 54px;
}
.cd-accordion-menu ul ul label::after,
.cd-accordion-menu ul ul a::after {
  left: 77px;
}
.cd-accordion-menu ul ul ul label,
.cd-accordion-menu ul ul ul a {
  padding-left: 118px;
}
.cd-accordion-menu ul ul ul label::before {
  left: 72px;
}
.cd-accordion-menu ul ul ul label::after,
.cd-accordion-menu ul ul ul a::after {
  left: 95px;
}
.val-level-1 {
    width: 105px;
  color: #7f8fa4;

}
#tab2 .val-level-1{
  width: 150px !important;  
}
.val-level-1:last-child {
	width: auto;
	min-width: 110px;
}
.highlight{
	display: block;
	padding: 10px 0;
	color: #2ea1f8;
	font-size: 24px;
	font-family: Source Sans Pro,sans-serif;
	line-height: 26px;
}
.bold{
	font-weight: bold;
}
.cd-accordion-menu.animated label::before {
    font-family: FontAwesome;
   content: "\f067";
   display: inline-block;
   padding-right: 3px;
   vertical-align: middle;
   font-size: 10px;
}

.cd-accordion-menu input[type=checkbox]:checked + label::before {
    font-family: FontAwesome;
   content: "\f068";
   display: inline-block;
   padding-right: 3px;
   vertical-align: middle;
}


@media screen and (max-width: 992px){
  
  
   
  
  .cd-accordion-menu {
		max-width: 100%;
	}
}

  .cd-accordion-menu label, .cd-accordion-menu a {
    padding: 0px 24px 0px 36px;
    font-size: 14px;
    line-height: 20px;
  }
  .cd-accordion-menu label::before {
    left: 12px;
  }
  .cd-accordion-menu label::after {
    left: 53px;
  }
  .cd-accordion-menu ul label,
  .cd-accordion-menu ul a {
    padding-left: 45px;
	padding-right: 0;
  background: #fff;
    color: #7f8fa4;
  }
  .cd-accordion-menu ul a:hover{
	text-decoration: none;  
  } 
  .cd-accordion-menu ul label::before {
    left: 25px;
  }
  .cd-accordion-menu ul label::after,
  .cd-accordion-menu ul a::after {
    left: 77px;
  }
  .cd-accordion-menu ul ul label,
  .cd-accordion-menu ul ul a {
    padding-left: 52px;
  }
  .cd-accordion-menu ul ul label::before {
    left: 36px;
  }
  .cd-accordion-menu ul ul label::after,
  .cd-accordion-menu ul ul a::after {
    left: 101px;
  }
  .cd-accordion-menu ul ul ul label,
  .cd-accordion-menu ul ul ul a {
    padding-left: 72px;
  }
  .cd-accordion-menu ul ul ul label::before {
    left: 53px;
  }
  .cd-accordion-menu ul ul ul label::after,
  .cd-accordion-menu ul ul ul a::after {
    left: 125px;
  }

.cd-accordion-menu.animated label::before {
  /* this class is used if you're using jquery to animate the accordion */
  -webkit-transition: -webkit-transform 0.3s;
  -moz-transition: -moz-transform 0.3s;
  transition: transform 0.3s;
}
