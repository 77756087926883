@import "~@ng-select/ng-select/themes/default.theme.css";

/* You can add global styles to this file, and also import other style files */
.margin-left-5 {
    margin-left: 5px !important;
}

.margin-right-5 {
    margin-right: 5px !important;
}

.margin-top-5 {
    margin-top: 5px !important;
}

.margin-top-15 {
    margin-top: 15px !important;
}

.margin-bottom-5 {
    margin-bottom: 5px !important;
}

.margin-bottom-5 {
    margin-bottom: 5px !important;
}

.margin-bottom-15 {
    margin-bottom: 15px !important;
}

.padding-left-5 {
    padding-left: 5px !important;
}

.padding-right-5 {
    padding-right: 5px !important;
}

.padding-top-5 {
    padding-top: 5px !important;
}

.padding-bottom-5 {
    padding-bottom: 5px !important;
}

.width-percent-5 {
    width: 5%;
}

.width-percent-10 {
    width: 10%;
}

.width-percent-15 {
    width: 15%;
}

.width-percent-20 {
    width: 20%;
}

.width-percent-30 {
    width: 30%;
}

.width-percent-40 {
    width: 40%;
}

.width-percent-50 {
    width: 50%;
}

.width-percent-100 {
    width: 100%;
}

/* Sweet alert */

.swal-footer {
    text-align: center;
}

.drpCableSizing-select .select2-container--default .select2-selection--single .select2-selection__arrow::after {
    content: none !important;
}

.width-100 .select2-container {
    width: 100% !important;
}

.ngx-slider .ngx-slider-bubble.ngx-slider-limit {
    color: #212529 !important;
}

.ngx-slider .ngx-slider-bubble {
    color: #212529 !important;
}

@media (max-width: 767px) {
    .mt-15-mobile {
        margin-top: 0.9375rem !important;
    }

    .CableCalcProtectiveDevice {
        margin-top: 15px;
    }

    #protectiveConfiguration .modal-dialog {
        max-width: 100% !important;
    }

    .mobile-layout .handle-mobile {
        width: 100%;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .mobile-layout .max-dis .select2-container {
        width: 175px !important;
    }

    .device-configuration {
        margin-top: 1.5rem;
    }

    .isFromCoordination .handle-mobile {
        margin-top: 10px;
    }
}

.ng-select-container {
    background: linear-gradient(to bottom, #fff, #f2f4f7) !important;
    border: 1px solid #dfe3e9 !important;
}

.ng-select .ng-select-container {
    color: rgba(53, 64, 82, 0.5) !important;
}

.ng-select.ng-select-single .ng-select-container {
    height: 39px !important;
}

.ng-select.ng-select-sm .ng-select-container {
    height: 33px !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
    color: #2fa2f9 !important;
    background-color: #f2f5f9 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    font-family: "Source Sans Pro", sans-serif !important;
    background: #f4f5f8 !important;
}

.ng-dropdown-panel {
    border: 1px solid #e6eaee !important;
    z-index: 2051 !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
    font-weight: 400 !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    color: rgba(53, 64, 82, 0.5) !important;
    border-top: 1px solid #e6eaee !important;
    font-weight: 500 !important;
    font-family:
        Source Sans Pro,
        sans-serif !important;
    padding: 5px 10px !important;
    font-size: 14px !important;
    line-height: 21px !important;
}

.ng-select .ng-arrow-wrapper {
    user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    -webkit-user-select: none !important;
    font-family: "LineAwesome" !important;
    text-decoration: inherit !important;
    text-rendering: optimizeLegibility !important;
    text-transform: none !important;
    -moz-osx-font-smoothing: grayscale !important;
    -webkit-font-smoothing: antialiased !important;
    font-smoothing: antialiased !important;
    border: 0 !important;
    top: 50% !important;
    position: absolute !important;
    margin-top: 0 !important;
    margin-left: 0 !important;
    font-size: 0.85rem !important;
    left: auto !important;
    right: 0 !important;
    display: inline-block !important;
    width: 1.9rem !important;
}

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
    box-shadow: unset !important;
}

.ng-dropdown-panel.ng-select-bottom {
    margin: 5px 0 !important;
    border-radius: 4px !important;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
    display: none !important;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container,
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
    width: 90% !important;
    line-height: 1.5 !important;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    font-weight: 400;
}

.ng-select .ng-arrow-wrapper:after {
    font-size: 16px !important;
    top: -19px !important;
    right: 2px !important;
    display: inline-block !important;
    position: absolute !important;
    height: 100% !important;
    margin-left: 1.25rem !important;
    padding: 0.5rem 8px !important;
    border-color: inherit !important;
    color: inherit !important;
    font-family: "FontAwesome" !important;
    content: "\f107" !important;
    line-height: 1.5 !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color: #f2f5f9 !important;
    color: #2fa2f9 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    font-family: "Source Sans Pro", sans-serif !important;
}

.multiline-options .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    white-space: normal !important;
}

#newcalcuation .card {
    border: unset !important;
    padding: 5px !important;
    border-bottom: 1px solid #b7bec5 !important;
    cursor: pointer;
}

#newcalcuation .card-hover:hover {
    transition: all 0.2s ease-out;
    background-color: #ddd !important;
    top: -4px;
}

#newcalcuation .card .card-body {
    padding: 0 !important;
}

#newcalcuation .card-title {
    font-size: 14px;
}

#newcalcuation .card-horizontal {
    display: flex;
    flex: 1 1 auto;
}

#newcalcuation .card-horizontal img {
    height: 45px !important;
}

#newcalcuation .img-square-wrapper {
    margin: auto !important;
}

#newcalcuation .check-icon {
    height: 100%;
    vertical-align: middle;
}

.bg-grey-active {
    background-color: #ddd !important;
}

@media (max-width: 767px) {
    #newcalcuation .card .card-body {
        padding: 0 !important;
    }

    #newcalcuation .card-horizontal img {
        height: 40px !important;
    }
}

@media (min-height: 900px) {
    #newcalcuation .card .card-body {
        padding: 0.5rem 0 !important;
    }

    #newcalcuation .card-horizontal img {
        height: 55px !important;
    }
}

.cplp_pricing-feature-txt {
    margin-bottom: 0 !important;
    margin-top: 5px;
    margin-left: 5px;
    font-weight: 600 !important;
}

.cable-sizing-plan-header {
    text-decoration: underline;
    font-family: "Montserrat", Sans-serif !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #505050 !important;
}

.cable-sizing-plan-text {
    margin: 15px 30px 0 30px !important;
}

.m-nav.m-nav--inline>.m-nav__item>.m-nav__link {
    padding: 0 !important;
    display: contents !important;
}

.m-nav.m-nav--inline>.m-nav__item {
    display: contents !important;
}

.m-nav .m-nav__item>.m-nav__link .m-nav__link-text {
    display: unset !important;
}

.m-subheader {
    margin: 10px !important;
    /* margin: 10px 0 !important; */
}

.m-nav__link-text {
    vertical-align: middle !important;
}

.ng-select.ng-select-disabled>.ng-select-container {
    background: #f4f5f8 !important;
    cursor: not-allowed;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
    cursor: not-allowed;
    color: #e8e8e8 !important;
}

.uoe-checkbox {
    min-height: 20px;
}

.uoe-checkbox>label {
    display: flex;
    margin-right: 5px;
}

.uoe-checkbox+.uoe-checkbox {
    margin-top: 10px;
}

.uoe-checkbox input[type="checkbox"] {
    margin-left: 0;
    border: 1px solid #bbc5d5;
    background: #fff;
    clear: none;
    cursor: pointer;
    line-height: 0;
    height: 20px;
    display: inline-block;
    outline: 0;
    text-align: center;
    width: 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    position: relative;
    appearance: none;
    transition: 0.05s border-color ease-in-out;
    border-radius: 4px;
}

.uoe-checkbox input[type="checkbox"]::before {
    content: "";
    position: absolute;
    top: 1px;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    speak: none;
    -webkit-font-smoothing: antialiased;
    transition:
        color 0.1s linear,
        background 0.1s linear;
    height: 20px;
    width: 19px;
    border-radius: 4px;
}

.uoe-checkbox input[type="checkbox"][disabled] {
    background: #eaeaea;
    border-style: dashed;
    cursor: not-allowed;
}

.uoe-checkbox input[type="checkbox"][disabled]:checked::before {
    color: #888;
}

.uoe-checkbox input[type="checkbox"]::before {
    font-size: 16px;
    line-height: 15px;
    font-family: FontAwesome;
    color: #fff;
}

.uoe-checkbox input[type="checkbox"]:checked::before {
    border: 1px solid #34aa44 !important;
    background-color: #39b54a;
    font-family: "FontAwesome";
    font-size: 15px;
    content: "\f00c";
    display: inline-block;
    position: relative;
    top: -1px;
    width: 20px;
    height: 20px;
    margin: 0 15px 0 0;
    transition: all 0.3s;
    border: 1px solid #bbc5d5;
    color: #fff;
    line-height: 15px;
    text-align: center;
    visibility: visible;
    left: -1px;
    padding-top: 1px;
}

.uoe-checkbox input[type="checkbox"]:indeterminate::before {
    content: "";
    top: 25% !important;
    height: 10px !important;
    width: 10px !important;
    margin: 0 auto;
    background-color: #39b54a;
    display: block;
    border-radius: 0;
    top: -1px;
}

.uoe-checkbox[class^="col-"] {
    margin: 0;
    margin-bottom: 0.5em;
}

th>.uoe-checkbox,
td>.uoe-checkbox {
    margin: 0;
}

.select-all-chk .mat-checkbox .mat-checkbox-frame {
    transform: scale(1.2);
    border-width: 1px !important;
}

.select-all-chk .mat-checkbox-checked .mat-checkbox-background {
    transform: scale(1.2);
}

.mat-checkbox-indeterminate .mat-checkbox-mixedmark {
    opacity: 1;
    content: "";
    background-color: blue;
    height: 10px !important;
    width: 10px !important;
    border-radius: 0;
}

/* .m-aside-menu .m-menu__nav>.m-menu__item>.m-menu__submenu .m-menu__subnav>.m-menu__item>.m-menu__link{
    padding-right: 15px !important;
} */

.m-aside-menu .custom-sidebar>.m-menu__item>.m-menu__link {
    padding: 9px 15px !important;
}

.m-aside-menu .custom-sidebar>.m-menu__item>.m-menu__submenu .m-menu__subnav>.m-menu__item>.m-menu__link {
    padding: 0 15px !important;
    padding-left: 35px !important;
}

.m-aside-menu.m-aside-menu--skin-light .custom-sidebar>.m-menu__item .m-menu__submenu .m-menu__item>.m-menu__heading .m-menu__link-text,
.m-aside-menu.m-aside-menu--skin-light .custom-sidebar>.m-menu__item .m-menu__submenu .m-menu__item>.m-menu__link .m-menu__link-text {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.m-aside-menu .custom-sidebar {
    list-style: disc !important;
    padding: 0px 0 45px 0 !important;
}

.bullet:before {
    content: "\2022";
    padding-right: 8px;
    color: #545874;
}

.popover .virtual-area {
    height: 11px;
    width: 100%;
    position: absolute;
}

.popover.bs-popover-top .virtual-area {
    bottom: -11px;
}

.popover.bs-popover-bottom .virtual-area {
    top: -11px;
}

.popover.bs-popover-left .virtual-area {
    right: -11px;
}

.popover.bs-popover-right .virtual-area {
    left: -11px;
}

.popover.popover-content {
    background-color: white;
    border: 0rem solid black;
    padding: 1.2rem;
    z-index: 1060;
    max-width: none;
    border-radius: 0rem;
    box-shadow:
        0 3px 3px 0 rgb(0 0 0 / 20%),
        0 6px 20px 0 rgb(0 0 0 / 19%) !important;
    font-family: "Helvetica";
}

.popover.popover-content .arrow {
    position: absolute;
    display: block;
    width: 2.2rem;
    height: 1.1rem;
    margin: 0 !important;
}

.popover.popover-content .arrow::before,
.popover.popover-content .arrow::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
}

.popover.popover-content.sm {
    width: 26rem;
}

.popover.popover-content.md-sm {
    width: 26rem;
}

.popover.popover-content.md {
    width: 34rem;
}

.popover.popover-content.lg {
    width: 40rem;
}

.popover.popover-content .popover-header {
    color: white;
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 2.1rem;
}

.popover.popover-content .popover-body {
    color: white;
    font-size: 1rem;
    line-height: 1.3rem;
}

.popover.popover-content.bs-popover-left {
    margin-left: calc(-1.1rem - 0.5rem);
}

.popover.popover-content.bs-popover-left .arrow:before,
.popover.popover-content.bs-popover-left .arrow:after {
    right: 0px !important;
}

.popover.popover-content.bs-popover-right {
    margin-left: calc(1.1rem + 0.5rem);
}

.popover.popover-content.bs-popover-right .arrow:before,
.popover.popover-content.bs-popover-right .arrow:after {
    left: 0px !important;
}

.popover.popover-content.bs-popover-top,
.popover.popover-content.bs-popover-top-right,
.popover.popover-content.bs-popover-top-left {
    margin-top: calc(-1.1rem - 0.5rem);
}

.popover.popover-content.bs-popover-top .arrow:before,
.popover.popover-content.bs-popover-top .arrow:after,
.popover.popover-content.bs-popover-top-right .arrow:before,
.popover.popover-content.bs-popover-top-right .arrow:after,
.popover.popover-content.bs-popover-top-left .arrow:before,
.popover.popover-content.bs-popover-top-left .arrow:after {
    bottom: 0px !important;
}

.popover.popover-content.bs-popover-bottom,
.popover.popover-content.bs-popover-bottom-right,
.popover.popover-content.bs-popover-bottom-left {
    margin-top: calc(1.1rem + 0.5rem);
}

.popover.popover-content.bs-popover-bottom .arrow:before,
.popover.popover-content.bs-popover-bottom .arrow:after,
.popover.popover-content.bs-popover-bottom-right .arrow:before,
.popover.popover-content.bs-popover-bottom-right .arrow:after,
.popover.popover-content.bs-popover-bottom-left .arrow:before,
.popover.popover-content.bs-popover-bottom-left .arrow:after {
    top: 0px !important;
}

.popover.popover-content.bs-popover-right .arrow,
.popover.popover-content.bs-popover-left .arrow {
    top: 50%;
    transform: translateY(-50%);
}

.popover.popover-content.bs-popover-top .arrow,
.popover.popover-content.bs-popover-bottom .arrow {
    left: 50%;
    transform: translateX(-50%);
}

.popover.popover-content.bs-popover-right .arrow {
    left: calc((1.1rem + 0rem) * -1);
    width: 1.1rem;
    height: 2.2rem;
    margin: 0 !important;
}

.popover.popover-content.bs-popover-right .arrow::before,
.popover.popover-content.bs-popover-right .arrow::after {
    border-width: calc(2.2rem / 2) 1.1rem calc(2.2rem / 2) 0;
}

.popover.popover-content.bs-popover-right .arrow::before {
    left: 0;
    border-right-color: black;
}

.popover.popover-content.bs-popover-right .arrow::after {
    left: 0rem !important;
    border-right-color: black;
}

.popover.popover-content.bs-popover-left .arrow {
    right: calc((1.1rem + 0rem) * -1);
    width: 1.1rem;
    height: 2.2rem;
    margin: 0 !important;
}

.popover.popover-content.bs-popover-left .arrow::before,
.popover.popover-content.bs-popover-left .arrow::after {
    border-width: calc(2.2rem / 2) 0 calc(2.2rem / 2) 1.1rem;
}

.popover.popover-content.bs-popover-left .arrow::before {
    right: 0;
    border-left-color: black;
}

.popover.popover-content.bs-popover-left .arrow::after {
    right: 0rem !important;
    border-left-color: black;
}

.popover.popover-content.bs-popover-top .arrow,
.popover.popover-content.bs-popover-top-right .arrow,
.popover.popover-content.bs-popover-top-left .arrow {
    bottom: calc((1.1rem + 0rem) * -1);
}

.popover.popover-content.bs-popover-top .arrow::before,
.popover.popover-content.bs-popover-top .arrow::after,
.popover.popover-content.bs-popover-top-right .arrow::before,
.popover.popover-content.bs-popover-top-right .arrow::after,
.popover.popover-content.bs-popover-top-left .arrow::before,
.popover.popover-content.bs-popover-top-left .arrow::after {
    border-width: 1.1rem calc(2.2rem / 2) 0;
}

.popover.popover-content.bs-popover-top .arrow::before,
.popover.popover-content.bs-popover-top-right .arrow::before,
.popover.popover-content.bs-popover-top-left .arrow::before {
    bottom: 0;
    border-top-color: black;
}

.popover.popover-content.bs-popover-top .arrow::after,
.popover.popover-content.bs-popover-top-right .arrow::after,
.popover.popover-content.bs-popover-top-left .arrow::after {
    bottom: 0rem !important;
    border-top-color: black;
}

.popover.popover-content.bs-popover-bottom .arrow,
.popover.popover-content.bs-popover-bottom-right .arrow,
.popover.popover-content.bs-popover-bottom-left .arrow {
    top: calc((1.1rem + 0rem) * -1);
}

.popover.popover-content.bs-popover-bottom .arrow::before,
.popover.popover-content.bs-popover-bottom .arrow::after,
.popover.popover-content.bs-popover-bottom-right .arrow::before,
.popover.popover-content.bs-popover-bottom-right .arrow::after,
.popover.popover-content.bs-popover-bottom-left .arrow::before,
.popover.popover-content.bs-popover-bottom-left .arrow::after {
    border-width: 0 calc(2.2rem / 2) 1.1rem calc(2.2rem / 2);
}

.popover.popover-content.bs-popover-bottom .arrow::before,
.popover.popover-content.bs-popover-bottom-right .arrow::before,
.popover.popover-content.bs-popover-bottom-left .arrow::before {
    top: 0;
    border-bottom-color: black;
}

.popover.popover-content.bs-popover-bottom .arrow::after,
.popover.popover-content.bs-popover-bottom-right .arrow::after,
.popover.popover-content.bs-popover-bottom-left .arrow::after {
    top: 0rem !important;
    border-bottom-color: black;
}

.popover.popover-content.bs-popover-bottom .popover-header::before,
.popover.popover-content.bs-popover-bottom-right .popover-header::before,
.popover.popover-content.bs-popover-bottom-left .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 2.2rem;
    margin-left: calc(2.2rem / -2);
    content: "";
    border-bottom: 0rem solid #6d6d6d;
}

.popover.popover-content.bs-popover-top-right,
.popover.popover-content.bs-popover-bottom-right {
    transform: translateX(-2rem);
}

.popover.popover-content.bs-popover-top-right .arrow,
.popover.popover-content.bs-popover-bottom-right .arrow {
    left: 0.9rem;
}

.popover.popover-content.bs-popover-top-left,
.popover.popover-content.bs-popover-bottom-left {
    transform: translateX(2rem);
}

.popover.popover-content.bs-popover-top-left .arrow,
.popover.popover-content.bs-popover-bottom-left .arrow {
    right: 0.9rem;
}

.popover.popover-content.bs-popover-bottom .arrow::after,
.popover.popover-content.bs-popover-bottom-right .arrow::after,
.popover.popover-content.bs-popover-bottom-left .arrow::after {
    top: 0rem !important;
    border-bottom-color: white;
}

::ng-deep .popover.popover-content {
    max-width: 100% !important;
    overflow: hidden !important;
}

.select2-container--default .select2-dropdown {
    z-index: 9999;
}

.calc-popover-btn a {
    color: #212529;
    text-decoration: none;
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 0;
    font-family: Poppins;
}

.calc-popover-btn label {
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 0;
    font-family: Poppins;
}

.project-popover-btn label {
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 0;
    font-family: Poppins;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
}

.filename_view--pageTitle--3u6np {
    flex: 0 1 auto;
    display: flex;
    /* height: var(--toolbar-height); */
    text-align: center;
    justify-content: center;
    min-width: 200px;
    box-sizing: border-box;
    fill: #212529;
    align-items: center;
}

.filename_view--folder--iZYVV,
.filename_view--section--8YRS6 {
    cursor: default;
    display: flex;
    overflow: hidden;
    max-width: 15vw;
    opacity: 1;
    transition: max-width 0.32s cubic-bezier(0, 0, 0.49, 0.99);
    transition-property: max-width, opacity;
    align-items: center;
}

.filename_view--folderNameInteractive---ALAG {
    border: 1px solid transparent;
    outline: none;
}

.filename_view--folderName--Q2b88 {
    color: #212529;
    max-width: 12vw;
    flex: 1 1 auto;
    cursor: pointer;
}

.ellipsis--ellipsis--70pHK {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.filename_view--pathSeparator--vS7s3 {
    display: inline-flex;
    align-items: center;
    margin: 0 5px;
    flex: 0 0 auto;
    color: #212529;
    fill: #212529;
    opacity: 0.6;
}

.filename_view--titleWithToggleContainer--aXP2c {
    max-width: 550px;
    z-index: 2;
}

.filename_view--title--Qhcgs {
    color: #212529;
    display: inline-block;
    vertical-align: top;
    border: 1px solid transparent;
    outline: none;
}

.filename_view--chevronNoMainContainer--g-pUb {
    padding: 0 5px;
}

@media (max-width: 350px) {

    .filename_view--folder--iZYVV,
    .filename_view--teamPaidStatusBadgeButton--kWOzh {
        display: none;
    }
}

.m-tabs-line .nav-item a:hover,
.m-tabs-line .nav-item a.active {
    color: #5867dd !important;
}

::ng-deep .cc-chat-bubble {
    width: 120px !important;
    /* change the width of button */
    height: 30px !important;
    /* change the height of button */
}

::ng-deep .widget-frame-outer {
    bottom: 67.5px !important;
    /* change the position of widget */
}

::ng-deep .new-chat-bubble {
    bottom: 67.5px !important;
    /* change the position of announcement */
}

::ng-deep .new-chat-bubble>div>div {
    color: #000000 !important;
    /* change the text color of announcement */
}

::ng-deep .new-chat-bubble>div:last-child {
    background: #fff4bd !important;
    /* change the backgound color of announcement */
}

#country .ng-select-container {
    border-radius: 10px;
    height: 48px !important;
}

#country .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    /* line-height: 31px !important; */
    padding: 10.5px 5px 10.5px 60px !important;
    cursor: pointer;
    -webkit-transition: 0.3s !important;
    transition: 0.3s;
    font-size: 14px !important;
    line-height: 150% !important;
    letter-spacing: 0.3px;
    text-overflow: unset !important;
    white-space: unset !important;
    text-align: left !important;
}

#country .ng-value-container .ng-value {
    /* line-height: 31px !important; */
    padding: 10.5px 15px 10.5px 50px !important;
    cursor: pointer;
    -webkit-transition: 0.3s !important;
    transition: 0.3s;
    font-size: 14px !important;
    line-height: 150% !important;
    letter-spacing: 0.3px;
    text-align: left !important;
}

@media (max-width: 767px) {
    .drp-currnecy {
        position: relative !important;
        margin-bottom: 15px !important;
    }
}

body:not(.modal-open) {
    padding-right: 0px !important;
}

.btn.disabled,
.btn:disabled {
    opacity: 0.65 !important;
    cursor: not-allowed !important;
}

#toast-container>.toast-success {
    background-image: none;
    padding: 15px 15px 15px 15px;
    opacity: 1;
    background-color: #fff;
    border-top: 7px solid #d1942e;
    color: #354052;
}

#toast-container .toast-message h5 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 10px;
}

.toast .toast-close-button {
    color: #354052;
}

.swal-overlay.swal-overlay--show-modal * {
    font-family: "Open Sans", sans-serif !important;
}

.avoidmultipleclick {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.6 !important;
    filter: alpha(opacity=60) !important;
}

.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    border-bottom: 1px solid #ddd;
}

/* Logo */
.navbar .logo {
    display: flex;
    align-items: center;
}

.navbar .logo img {
    height: 40px;
    margin-right: 10px;
}

.navbar .logo span {
    font-size: 18px;
    font-weight: bold;
    color: #333;
}

/* Navbar buttons */
.navbar .nav-buttons {
    display: flex;
    gap: 10px;
}

.navbar .nav-buttons button {
    padding: 12px 16px !important;
    border: none;
    border-radius: 5px;
    background-color: #d1942e;
    color: #333;
    /* cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease; */
}

.navbar .nav-buttons button:hover {
    background-color: #e0c089;
}

/* Responsive styling */
@media (max-width: 600px) {
    .navbar {
        flex-direction: column;
        align-items: flex-start;
    }

    .navbar .nav-buttons {
        flex-direction: row;
        width: 100%;
        display: flex;
        margin: 0 auto;
    }

    .navbar .nav-buttons button {
        width: 100%;
        margin-top: 5px;
    }
}

ngb-modal-window.modal {
    z-index: 1056;
}

:root {
    color-scheme: light !important;
}

@media (prefers-color-scheme:light) {
    :root {
        color-scheme: light !important;
    }
}

.mat-mdc-table .mat-mdc-header-cell {
    border: none;
}

.p-datatable-tbody .ui-column-title{
    display: none !important;
}